@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.col {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.colHeader {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 12px 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}
.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    width: 200px;
    padding: 4px 0;
  }
}
